import { dehydrate } from '@tanstack/react-query';
import { DEFAULT_LOCALE } from 'constants/i18n';
import { REVALIDATION_TIME } from 'constants/isr';
import LoginContainer from 'containers/LoginContainer';
import { beforeGetStaticProps } from 'helpers/AxiosWrapper';
import { pathnames } from 'i18n/pathnames';
import { GetStaticProps } from 'next';

export const getStaticProps: GetStaticProps = async (context) => {
  if (context.locale === DEFAULT_LOCALE) {
    return {
      notFound: true,
      props: {},
    };
  }
  const { queryClient } = await beforeGetStaticProps(context, pathnames.LOGIN);
  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: REVALIDATION_TIME,
  };
};

const Login = () => <LoginContainer />;

export default Login;

Login.getLayout = LoginContainer.getLayout;
