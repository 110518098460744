import useRouter from 'hooks/useRouter';
import { useEffect } from 'react';
import { pathnames } from '../i18n/pathnames';
import { getLocalizedRoute } from '../utils/localizedRouteUtil';
import useAuthResolver from './useAuthResolver';

interface UseAuthenticationProps {
  redirectIfFound?: boolean;
  redirectIfNotFound?: boolean;
  redirectUrl?: string;
}

const useAuthentication = ({
  redirectIfFound = false,
  redirectIfNotFound = true,
  redirectUrl = pathnames.LOGIN,
}: UseAuthenticationProps = {}) => {
  const router = useRouter();
  const { locale } = router;

  const { authResolved, user } = useAuthResolver();

  const localizedRedirectUrl = getLocalizedRoute(redirectUrl, locale) || redirectUrl;

  const handleRedirect = () => {
    if ((redirectIfNotFound && !user) || (redirectIfFound && user)) {
      router.push(localizedRedirectUrl);
    }
  };

  useEffect(() => {
    if (authResolved) {
      handleRedirect();
    }
  }, [authResolved]);

  return { user };
};

export default useAuthentication;
