import useRouter from 'hooks/useRouter';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAuthActions } from 'store/auth';
import { getRouterParam } from 'utils/urlUtil';
import Flex from '../components/Flex/Flex';
import Alert from '../components/Layout/Alert/Alert';
import Link from '../components/Link/Link';
import LoginForm from '../components/Login/LoginForm/LoginForm';
import PageLayout from '../components/PageLayout';
import SEO from '../components/Seo/SEO';
import Typography from '../components/Typography/Typography';
import { ALERT_TYPES } from '../constants/alerts';
import { ERROR_CODES } from '../constants/errors';
import useAuthentication from '../hooks/useAuthentication';
import { useErrorMessages } from '../hooks/useErrorMessages';
import { pathnames } from '../i18n/pathnames';
import { log } from '../utils/loggerUtil';

const LoginContainer = () => {
  const router = useRouter();
  const { query } = router;
  const { formatMessage } = useIntl();
  const { login } = useAuthActions();
  const queryRedirect = getRouterParam(query?.redirect);

  const { user } = useAuthentication({
    redirectIfFound: true,
    redirectIfNotFound: false,
    redirectUrl: queryRedirect ?? pathnames.MY_ACCOUNT,
  });
  const { getErrorMessage: getErrorMessages } = useErrorMessages();

  const [error, setError] = useState<string>();

  const onLoginSubmit = async ({ email, password }: { email: string; password: string }) => {
    try {
      await login({ email: email?.toLowerCase(), password });

      const redirectUrl = queryRedirect || pathnames.MY_ACCOUNT;

      router.push(redirectUrl);
    } catch (err) {
      setError(getErrorMessages({ type: ERROR_CODES.LOGIN_ERROR }));
      log('onSubmit - pages/login', `User login failed`, err);
    }
  };

  if (user) {
    return null;
  }

  return (
    <SEO useAsPath>
      <main>
        <Flex alignItems="center" flexDirection="column">
          <Typography type="h1">{formatMessage({ id: 'login_title' })}</Typography>
          <Typography marginBottom={0} type="p">
            {formatMessage({ id: 'login_subtitle' })}
          </Typography>
          <Typography type="p">
            {formatMessage({
              id: 'login_registration_title_text',
            })}{' '}
            <Link url={pathnames.REGISTER}>
              {formatMessage({
                id: 'login_register_btn',
              })}
            </Link>
          </Typography>

          <div className="w-full sm:w-1/3">
            {!!error && <Alert type={ALERT_TYPES.DANGER}>{error}</Alert>}

            <LoginForm onSubmit={onLoginSubmit} />
          </div>
        </Flex>
      </main>
    </SEO>
  );
};

export default LoginContainer;

LoginContainer.getLayout = function getLayout(page: ReactNode) {
  return <PageLayout withSpace>{page}</PageLayout>;
};
