import { IOS_APP_DATA } from 'constants/app';
import { IS_KREFEL } from 'constants/brand';
import { isVercel } from 'constants/environment';
import { ISO_LOCALES } from 'constants/i18n';
import useRouter from 'hooks/useRouter';
import { NO_INDEX_PAGES } from 'i18n/pathnames';
import Head from 'next/head';
import config from '../../config';
import useLocalizedUrls from '../../hooks/useLocalizedUrls';
import { excludeDefaultLocale } from '../../utils/localization';
import { removeTrailingSlash } from '../../utils/textUtil';

const ALLOWED_SEARCH_PARAMS = ['currentPage'];

interface PageHeadProps {
  basePathOverwrite?: string;
  description?: string;
  robots?: string;
  title?: string;
}

const PageHead = ({ basePathOverwrite, description, robots = 'follow, index', title }: PageHeadProps) => {
  const host = process.env.NEXT_PUBLIC_NODE_HTTP_ORIGIN;
  const { getLocalizedPathnameByLocale } = useLocalizedUrls();
  const { asPath, locale: currentLocale, locales } = useRouter();
  const isoLocales: { [key: string]: string | undefined } = ISO_LOCALES[config.platform];

  const alternateUrls = excludeDefaultLocale(locales)?.map((alternateLocale) => ({
    key: alternateLocale,
    value: getLocalizedPathnameByLocale(alternateLocale),
  }));

  const normalizeUrl = (url: string) => removeTrailingSlash(url?.split('?')?.[0]);

  const localizedPathname = getLocalizedPathnameByLocale(currentLocale);

  const searchParams = new URLSearchParams(asPath?.split('?')?.[1]);
  const whiteListedSeachParams = ALLOWED_SEARCH_PARAMS.reduce((param, key) => {
    const value = searchParams.get(key);
    if (value && Number(value) !== 0) {
      param.set(key, value);
    }
    return param;
  }, new URLSearchParams());
  const canonicalUrl = `${host}${localizedPathname}${
    Array.from(whiteListedSeachParams.keys()).length ? '?' : ''
  }${whiteListedSeachParams.toString()}`;

  const normalizedAsPath = asPath?.split('?')?.[0]?.split('#')?.[0];
  const isNoIndexPage = NO_INDEX_PAGES.includes(normalizedAsPath);
  const robotsContent = isVercel || isNoIndexPage ? 'noindex, nofollow' : robots;

  return (
    <Head>
      <title key="title">{title}</title>
      <meta key="description" content={description} name="description" />
      <link key="canonical" href={canonicalUrl} rel="canonical" />
      {alternateUrls?.map(({ key, value }) => {
        const url = basePathOverwrite || value;
        return <link key={key} href={normalizeUrl(`${host}${url}`)} hrefLang={isoLocales[key]} rel="alternate" />;
      })}
      <meta key="og:title" content={title} property="og:title" />
      <meta key="og:description" content={description} property="og:description" />
      <meta key="twitter:title" content={title} name="twitter:title" />
      <meta key="twitter:description" content={description} name="twitter:description" />
      <meta content={robotsContent} name="robots" />
      {IS_KREFEL && (
        <>
          {/* Krëfel App Banner for iOS */}
          <meta content={`app-id=${IOS_APP_DATA.appID}, app-argument=krefel://`} name={IOS_APP_DATA.appMeta} />
          {/* The following code is to redirect to the installed app */}
          {/* TODO */}
          {/* iOS App */}
          {/* <meta property="al:ios:url" content="krefel://" />
          <meta property="al:ios:app_store_id" content={IOS_APP_DATA.appID} />
          <meta property="al:ios:app_name" content="Krëfel" /> */}
          {/* TODO */}
          {/* Android App */}
          {/* <meta property="al:android:url" content="krefel://" />
          <meta property="al:android:package" content="bg.krefel.app" />
          <meta property="al:android:app_name" content="Krëfel" /> */}
        </>
      )}
    </Head>
  );
};

export default PageHead;
